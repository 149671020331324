import React from 'react'
import videojs from 'video.js'
import { v4 as uuidv4 } from 'uuid';
import 'video.js/dist/video-js.css'
import iconWarning from './icon_warning.svg'
import './player.css'
import './overlay.css'

// import './vjsLogoOverlay'
// import vjsLogoOverlay from './vjsLogoOverlay'

window.player = videojs

export default class Player extends React.Component {
  slackWebhook = 'https://hooks.slack.com/services/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'

  constructor(props) {
    super(props)

    this.state = {
      sid: '',
      language: 'zh-tw',
      videoId: '',
      source: '',
      isLive: false,
      guid: '',
      currentTime: 0,
      maxViewTime: 0,
      firstTime: true,

      seekBar: 'enable',
      fullscreen: 'enable',
      seekForward: 'enable',

      learningRecordInterval: 5000,

      watermarkDateTime: 'hide',
      watermarkDate: '',
      watermarkTime: '',
      dateTimeDate: 0,
      watermarkStudent: 'hide',
      watermarkStudentText: '',
      watermarkLogo: 'hide',
      watermarkLogoImage: '',
      watermarkCourse: 'hide',
      watermarkCourseText: '',
      watermarkTeacher: 'hide',
      watermarkTeacherText: '',
      
      warning: 'hide',
      warningText: '無法播放影片',

      onSeeking: false,
      playStatus: 'pause',

      subtitles: [],
      subtitle: '',
      slides: '',
    }

    this.watermarkDateTimeOverlay = this.watermarkDateTimeOverlay.bind(this)
    this.watermarkStudentOverlay = this.watermarkStudentOverlay.bind(this)
    // this.watermarkLogoOverlay = this.watermarkLogoOverlay.bind(this)

    this.warningOverlay = this.warningOverlay.bind(this)
  }

  // player.controlBar.playToggle.off("click")
  // player.controlBar.playToggle.on("click", player.controlBar.playToggle.onClick)
  // however onClick isn't exposed in the minified version so that wouldn't work as is

  async sendPlayerStatus(msg) {
    await fetch(this.slackWebhook, {
      method: 'POST',
      body: JSON.stringify({
        text: msg,
      }),
    })
  }

  setSourceURL(source) {
    window.player.src({
      src: source,
      type: "application/x-mpegURL"
    })
    window.player.controls(true)
  }
  setCurrentTime(time) {
    if(!this.state.isLive) {
      if(typeof time === 'number' && time >= 0) window.player.currentTime(time)
    }
    // this.setState({ onSeeking: false })
  }
  togglePlay() {
    window.player.play()
  }
  togglePause() {
    window.player.pause()
  }
  enableSeekBar() {
    window.player.controlBar.progressControl.seekBar.show()
    this.setState({ seekBar: 'enable' })
  }
  disableSeekBar() {
    window.player.controlBar.progressControl.seekBar.hide()
    // window.player.controlBar.progressControl.seekBar.disable()
    this.setState({ seekBar: 'disable' })
  }
  enableFullscreen() {
    // window.player.controlBar.fullscreenToggle.show()
    this.setState({ fullscreen: 'enable' })
    window.player.controlBar.el_.childNodes.forEach(ele => {
      if(ele.id === 'windowFullscreenButton') {
        ele.innerHTML = '<span class="vjs-icon-placeholder vjs-icon-fullscreen-enter"></span>'
        ele.disabled = false
      }
    })
  }
  disableFullscreen() {
    // window.player.controlBar.fullscreenToggle.hide()
    console.log('fullscreen disable')
    this.setState({ fullscreen: 'disable' })
    window.player.controlBar.el_.childNodes.forEach(ele => {
      if(ele.id === 'windowFullscreenButton') {
        ele.innerHTML = '<span class="vjs-icon-placeholder"></span>'
        ele.disabled = true
      }
    })
  }
  updateViewTime() {
    if(this.state.onSeeking === false) {
      const currentTime = Math.floor(window.player.currentTime())
      if(currentTime > this.state.maxViewTime) {
        this.setState({ maxViewTime: currentTime })
      }
      this.setState({ currentTime: currentTime })

      // console.log('maxViewTime: ' + this.state.maxViewTime)
      // console.log('currentTime: ' + currentTime)
    }
  }
  learningRecord() {
    if(window.player.readyState() > 0) {
      window.parent.postMessage({
        learningRecord: {
          sid: this.state.sid,
          guid: this.state.guid,
          firstTime: this.state.firstTime,
          language: this.state.language,
          videoId: this.state.videoId,
          currentTime: this.state.currentTime,
          maxViewTime: this.state.maxViewTime
        } 
      }, document.referrer)
      this.setState({ firstTime: false })
    }
  }

  dateTimePatchZero(num) {
    if(num < 10) return `0${num}`
    return num
  }
  updateDateTime() {
    if(this.state.watermarkDateTime === 'show') {
      const nowDateTime = new Date()
      if(nowDateTime.getDate() !== this.state.dateTimeDate) {
        this.setState({ watermarkDate: `${nowDateTime.getFullYear()}/${this.dateTimePatchZero(nowDateTime.getMonth() + 1)}/${this.dateTimePatchZero(nowDateTime.getDate())}` })
      }
      this.setState({ watermarkTime: `${this.dateTimePatchZero(nowDateTime.getHours())}:${this.dateTimePatchZero(nowDateTime.getMinutes())}:${this.dateTimePatchZero(nowDateTime.getSeconds())}` })
    }
  }

  componentDidMount() {
    const options = {
      errorDisplay: false,
      playsinline: true,
      html5: {
        nativeTextTracks: true,
        // hls: {
        //   overrideNative: !videojs.browser.IS_ANY_SAFARI
        // }
      },
      controlBar: { 
        pictureInPictureToggle: false,
        fullscreenToggle: false
      },
      userActions: {
        doubleClick: false
      },
      languages: {
        zh: {
          // 'Play': 'Play',
          // 'Pause': 'Pause',
          // 'Current Time': '現在時間',
          // 'Duration': '影片長度',
          // 'Remaining Time': '剩餘長度',
          // 'Stream Type': '串流類型',
          // 'LIVE': '直播',
          // 'Loaded': '已載入',
          // 'Progress': '進度',
          // 'Fullscreen': '全螢幕',
          // 'Non-Fullscreen': '非全螢幕',
          // 'Mute': 'Mute',
          // 'Unmute': 'Unmute',
          // 'Playback rate': '播放速率',
          // 'Subtitles': '字幕',
          'subtitles off': 'OFF',
          // 'Captions': '字幕',
          'captions off': 'OFF',
          // 'Chapters': '章節',
          // 'Close Modal Dialog': '關閉快顯視窗',
          // 'You aborted the video playback': '您暫停了影片播放',
          // 'A network error caused the video download to fail part-way.': '網路錯誤，無法載入影片',
          // 'The video could not be loaded, either because the server or network failed or because the format is not supported.': '影片載入錯誤',
          // 'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.': '影片格式錯誤，無法播放',
          // 'No compatible source was found for this video.': '無法載入影片來源'
        }
      },
      language: 'zh'
    }

    window.player = this.player = videojs(this.videoNode, options, onPlayerReady => {
      // console.log('onPlayerReady', this)

      const watermarkBottom = document.createElement('div')
      watermarkBottom.id = 'watermarkBottom'
      watermarkBottom.className = 'watermarkBottom'
      watermarkBottom.innerHTML = `
        <div id="watermarkLogo"><img id="watermarkLogoImg" src="" alt="" /></div>
        <div id="watermarkCourse"><div id="watermarkCourseText"></div></div>
        <div id="watermarkTeacher"><div id="watermarkTeacherText"></div></div>
      `
      window.player.el().insertBefore(watermarkBottom, window.player.controlBar.el())
      window.player.addChild(watermarkBottom, {}, 6)

      window.player.tech_.on('retryplaylist', () => {
        // console.log('retrying')
      })

      if(this.state.fullscreen === 'enable') {
        // window.player.controlBar.fullscreenToggle.off('click')
        // window.player.controlBar.fullscreenToggle.on('click', () => {
        //   window.parent.postMessage({ playerStatus: 'windowfullscreen' }, document.referrer)
        // })

        const windowFullscreen = window.player.controlBar.addChild('button')
        const windowFullscreenButton = windowFullscreen.el()
        windowFullscreenButton.id = 'windowFullscreenButton'
        windowFullscreenButton.innerHTML = '<span class="vjs-icon-placeholder vjs-icon-fullscreen-enter"></span>'
        windowFullscreenButton.addEventListener('click', () => {
          window.parent.postMessage({ playerStatus: 'windowfullscreen' }, document.referrer)
        })
        windowFullscreenButton.addEventListener('touchstart', () => {
          window.parent.postMessage({ playerStatus: 'windowfullscreen' }, document.referrer)
        })
      }

      window.player.on('error', () => {
        window.parent.postMessage({
          playerStatus: 'error',
          errorCode: window.player.error().code,
          errorMessage: window.player.error().message
        }, document.referrer)
        
        window.player.controls(false)
        this.setState({ 
          watermark: 'disable',
          watermarkDateTime: 'hide',
          watermarkStudent: 'hide',
          watermarkLogo: 'hide',
          watermarkCourse: 'hide',
          watermarkTeacher: 'hide',
          warning: 'show',
          warningText: '您的載具網路環境/防火牆設定不支援播放，建議改用個人電腦/手機與非公司網路(如家用網路、個人手機行動網路)。'
        })

        document.getElementById('watermarkLogo').style.display = 'none'
        document.getElementById('watermarkCourse').style.display = 'none'
        document.getElementById('watermarkTeacher').style.display = 'none'
      })

      window.player.on('loadedmetadata', () => {
        // console.log('on loadedmetadata')
        if(window.player.duration() === Infinity) {
          this.setState({ isLive: true })
        } else {
          this.setState({ isLive: false })
        }
      })

      window.player.on('loadeddata', () => {
        // console.log('on loadeddata')
        // console.log('duration: ' + window.player.duration())
        // console.log('state.isLive: ' + this.state.isLive)
        // console.log('state.sid: ' + this.state.sid)
        if(this.state.slides !== '') {
          const tracks = window.player.textTracks()
          tracks.tracks_.forEach((track) => {
            if(track.kind === 'metadata' && track.label === 'slides') {
              track.mode = 'hidden'
              track.addEventListener('cuechange', (event) => {
                const cues = event.target.activeCues
                if(cues[0]) {
                  // console.log(cues[0].text)
                  window.parent.postMessage({ slides: cues[0].text }, document.referrer)
                }
              })
            }
          })
        }
        if(this.state.subtitle !== '') {
          const tracks = window.player.textTracks()
          tracks.tracks_.forEach((track) => {
            if(track.kind === 'subtitles' && track.language === this.state.subtitle) {
              track.mode = 'showing'
            } else if(track.kind === 'subtitles') {
              track.mode = 'disabled'
            }
          })
        }

        // window.parent.postMessage({ playerStatus: 'sourceloaded' }, document.referrer)
      })

      document.querySelector('video').addEventListener('touchstart', (e) => {
        // e.preventDefault()
        if(this.state.playStatus === 'playing') {
          this.togglePause()
        } else {
          this.togglePlay()
        }
      }, {passive: false})
      // window.player.on('touchstart', (e) => {
      //   // e.preventDefault()
      //   if(this.state.playStatus === 'playing') {
      //     this.togglePause()
      //   } else {
      //     this.togglePlay()
      //   }
      // })

      window.player.on('canplay', () => {
        window.parent.postMessage({ playerStatus: 'sourceloaded' }, document.referrer)
      })

      window.player.on('play', () => {
        // console.log('on play')
        let guid = uuidv4()
        this.setState({
          guid: guid,
          firstTime: true
        })
        this.setState({ playStatus: 'playing' })
        if(!this.playerTimer) this.playerTimer = setInterval(() => this.updateViewTime(), 1000)
        this.learningRecord()
        if(!this.learningRecordTimer) this.learningRecordTimer = setInterval(() => this.learningRecord(), this.state.learningRecordInterval)
      })

      window.player.on('pause', () => {
        // console.log('on pause')
        this.setState({ playStatus: 'paused'})

        if(this.playerTimer) {
          clearInterval(this.playerTimer)
          this.playerTimer = false
        }
        if(this.learningRecordTimer) {
          clearInterval(this.learningRecordTimer)
          this.learningRecordTimer = false
          this.learningRecord()
        }
      })

      window.player.on('timeupdate', () => {
      })

      window.player.on('seeking', () => {
        // console.log('on seeking')
        // console.log('seeking currentTime: ' + window.player.currentTime())
        this.setState({ onSeeking: true })
        if(this.playerTimer) {
          clearInterval(this.playerTimer)
          this.playerTimer = false
        }
        if(this.learningRecordTimer) {
          clearInterval(this.learningRecordTimer)
          this.learningRecordTimer = false
        }
      })

      window.player.on('seeked', () => {
        // console.log('on seeked')
        if(this.state.seekForward === 'disable') {
          const currentTime = window.player.currentTime()
          if(currentTime > this.state.maxViewTime) {
            window.player.currentTime(this.state.maxViewTime)
          }
        }
        this.setState({ onSeeking: false })

        if(this.state.playStatus === 'playing') {
          window.player.pause()
          window.player.play()
        }
      })

      window.player.on('ended', () => {
        // console.log('iframe on ended')
        window.parent.postMessage({ playerStatus: 'ended' }, document.referrer)
      })
      
      window.addEventListener('message', (event) => {
        // console.log("iframe recived message")

        if(event.data.playerSetup) {
          // console.log("in playerSetup")
          let sid = event.data.playerSetup.sid? event.data.playerSetup.sid : ''
          let language = event.data.playerSetup.language? event.data.playerSetup.language : 'zh'
          let videoId = event.data.playerSetup.videoId? event.data.playerSetup.videoId : ''
          let source = event.data.playerSetup.source? event.data.playerSetup.source : ''
          let currentTime = event.data.playerSetup.currentTime? event.data.playerSetup.currentTime : 0
          let maxViewTime = event.data.playerSetup.maxViewTime? event.data.playerSetup.maxViewTime : 0
          let firstTime = true
          let seekBar = event.data.playerSetup.seekBar? event.data.playerSetup.seekBar : 'enable'
          let fullscreen = event.data.playerSetup.fullscreen? event.data.playerSetup.fullscreen : 'enable'
          let seekForward = event.data.playerSetup.seekForward
          let learningRecordInterval = event.data.playerSetup.learningRecordInterval? event.data.playerSetup.learningRecordInterval * 1000 : 5000
          let watermarkDateTime = event.data.playerSetup.watermarkDateTime? event.data.playerSetup.watermarkDateTime: 'hide'
          let watermarkStudent = event.data.playerSetup.watermarkStudent? event.data.playerSetup.watermarkStudent: 'hide'
          let watermarkStudentText = event.data.playerSetup.watermarkStudentText? event.data.playerSetup.watermarkStudentText: ''
          let watermarkLogo = event.data.playerSetup.watermarkLogo? event.data.playerSetup.watermarkLogo: 'hide'
          let watermarkLogoImage = event.data.playerSetup.watermarkLogoImage? event.data.playerSetup.watermarkLogoImage: ''
          let watermarkCourse = event.data.playerSetup.watermarkCourse? event.data.playerSetup.watermarkCourse: 'hide'
          let watermarkCourseText = event.data.playerSetup.watermarkCourseText? event.data.playerSetup.watermarkCourseText: ''
          let watermarkTeacher = event.data.playerSetup.watermarkTeacher? event.data.playerSetup.watermarkTeacher: 'hide'
          let watermarkTeacherText = event.data.playerSetup.watermarkTeacherText? event.data.playerSetup.watermarkTeacherText: ''
          let warning = 'hide'
          let subtitles = event.data.playerSetup.subtitles? event.data.playerSetup.subtitles: []
          let subtitle = event.data.playerSetup.subtitle? event.data.playerSetup.subtitle: ''
          let slides = event.data.playerSetup.slides? event.data.playerSetup.slides: ''

          this.setSourceURL(source)
          if(currentTime > 0) this.setCurrentTime(currentTime)
          if(seekBar === 'enable') {
            this.enableSeekBar()
          } else {
            this.disableSeekBar()
          }
          if(fullscreen === 'enable') {
            this.enableFullscreen()
          } else {
            this.disableFullscreen()
          }

          this.setState({
            sid: sid,
            language: language,
            videoId: videoId,
            source: source,
            currentTime: currentTime,
            maxViewTime: maxViewTime,
            firstTime: firstTime,
            seekBar: seekBar,
            fullscreen: fullscreen,
            seekForward: seekForward,
            learningRecordInterval: learningRecordInterval,
            watermarkDateTime: watermarkDateTime,
            watermarkStudent: watermarkStudent,
            watermarkStudentText: watermarkStudentText,
            watermarkLogo: watermarkLogo,
            watermarkLogoImage: watermarkLogoImage,
            watermarkCourse: watermarkCourse,
            watermarkCourseText: watermarkCourseText,
            watermarkTeacher: watermarkTeacher,
            watermarkTeacherText: watermarkTeacherText,
            warning: warning,
            subtitles: subtitles,
            subtitle: subtitle,
            slides: slides,
          })

          window.watermarkLogo = this.state.watermarkLogo
          if(window.watermarkLogo === 'show') {
            document.getElementById('watermarkLogo').style.display = 'flex'
          } else {
            document.getElementById('watermarkLogo').style.display = 'none'
          }
          window.watermarkLogoImage = this.state.watermarkLogoImage
          document.getElementById('watermarkLogoImg').src = window.watermarkLogoImage
          window.watermarkCourse = this.state.watermarkCourse
          if(window.watermarkCourse === 'show') {
            document.getElementById('watermarkCourse').style.display = 'flex'
          } else {
            document.getElementById('watermarkCourse').style.display = 'none'
          }
          window.watermarkCourseText = this.state.watermarkCourseText
          document.getElementById('watermarkCourseText').innerHTML = window.watermarkCourseText
          window.watermarkTeacher = this.state.watermarkTeacher
          if(window.watermarkTeacher === 'show') {
            document.getElementById('watermarkTeacher').style.display = 'flex'
          } else {
            document.getElementById('watermarkTeacher').style.display = 'none'
          }
          window.watermarkTeacherText = this.state.watermarkTeacherText
          document.getElementById('watermarkTeacherText').innerHTML = window.watermarkTeacherText

          // const oldTextTracks = window.player.textTracks()
          // oldTextTracks.tracks_.forEach((track) => {
          //   console.log('REMOVING')
          //   window.player.removeRemoteTextTrack(track)
          // })

          // window.player.language(language)
          subtitles.forEach((subtitle) => {
            const track = window.player.addRemoteTextTrack({
              kind: 'subtitles',
              src: subtitle.src,
              srclang: subtitle.lang,
              label: subtitle.label
            }, false)
            track.addEventListener('load', function() {
              // console.log('Text track ' + subtitle.label + ' loaded')
            })
            track.addEventListener('active', function() {
              // console.log('Text track ' + subtitle.label + ' Unloaded')
            })
          })

          if(subtitles.length > 0) {
            const textTracks = window.player.textTracks()
            textTracks.on('change', (event) => {
              let language = null
              textTracks.tracks_.forEach((track) => {
                if(track.kind === 'subtitles' && track.mode === 'showing') {
                  language = track.language
                } else {
                  if(!language) language = 'off'
                }
              })
              window.parent.postMessage({ subtitle: language }, document.referrer)
              // console.log(language)
            })
          }

          if(slides !== '') {
            // console.log(slide)
            window.player.addRemoteTextTrack({
              kind: 'metadata',
              src: slides,
              srclang: 'slides',
              label: 'slides'
            }, false)
          }

          // const textTrackSettings = window.player.textTrackSettings;
          // textTrackSettings.setValues({
          //   'backgroundColor': '#000000',
          //   'edgeStyle': 'uniform',
          // })
          // textTrackSettings.updateDisplay()

          // window.player.on('subtitleChanged', function (event, track) {
          //   console.log('subtitle changed')
          // })
          // this.player.addChild('vjsLogoOverlay', { watermarkLogoImage: this.state.watermarkLogoImage }, 5)
          // this.player.addChild('vjsLogoOverlay', { ...this.state }, 5)
        
        } else if(event.data.playerControl) {
          if(event.data.playerControl.playControl) {
            if(event.data.playerControl.playControl === 'play') {
              this.togglePlay()
            } else if(event.data.playerControl.playControl === 'pause') {
              this.togglePause()
            }
          
          } else if(event.data.playerControl.seekBar) {
            if(event.data.playerControl.seekBar === 'disable') {
              this.disableSeekBar()
            } else if(event.data.playerControl.seekBar === 'enable') {
              this.enableSeekBar()
            }
          
          } else if(event.data.playerControl.fullscreen) {
            if(event.data.playerControl.fullscreen === 'disable') {
              this.disableFullscreen()
            } else if(event.data.playerControl.fullscreen === 'enable') {
              this.enableFullscreen()
            }
          
          } else if(event.data.playerControl.seekTo) {
            this.setCurrentTime(event.data.playerControl.seekTo)
            // window.player.currentTime(event.data.playerControl.seekTo)
          
          } else if(event.data.playerControl.watermarkDateTime) {
            if(event.data.playerControl.watermarkDateTime === 'show') {
              this.setState({ watermarkDateTime: 'show' })
            } else {
              this.setState({ watermarkDateTime: 'hide' })
            }
          
          } else if(event.data.playerControl.watermarkStudent) {
            if(event.data.playerControl.watermarkStudent === 'show') {
              this.setState({ watermarkStudent: 'show' })
            } else {
              this.setState({ watermarkStudent: 'hide' })
            }
          
          } else if(event.data.playerControl.watermarkLogo) {
            if(event.data.playerControl.watermarkLogo === 'show') {
              this.setState({ watermarkLogo: 'show' })
              document.getElementById('watermarkLogo').style.display = 'flex'
            } else {
              this.setState({ watermarkLogo: 'hide' })
              document.getElementById('watermarkLogo').style.display = 'none'
            }
          
          } else if(event.data.playerControl.watermarkCourse) {
            if(event.data.playerControl.watermarkCourse === 'show') {
              this.setState({ watermarkCourse: 'show' })
              document.getElementById('watermarkCourse').style.display = 'flex'
            } else {
              this.setState({ watermarkCourse: 'hide' })
              document.getElementById('watermarkCourse').style.display = 'none'
            }
          
          } else if(event.data.playerControl.watermarkTeacher) {
            if(event.data.playerControl.watermarkTeacher === 'show') {
              this.setState({ watermarkTeacher: 'show' })
              document.getElementById('watermarkTeacher').style.display = 'flex'
            } else {
              this.setState({ watermarkTeacher: 'hide' })
              document.getElementById('watermarkTeacher').style.display = 'none'
            }
          
          } else if(event.data.playerControl.warning) {
            if(event.data.playerControl.warning === 'show') {
              this.setState({ warning: 'show', warningText: event.data.playerControl.warningText })
              this.togglePause()
              window.player.controls(false)
            } else {
              this.setState({ warning: 'hide' })
              window.player.controls(true)
            }

          } else if(event.data.playerControl.player) {
            if(event.data.playerControl.player === 'unload') {
              if(window.player) window.player.dispose()
            }
          }
        }
      })

      // if(window.self !== window.top) {
      if(true) {
        window.parent.postMessage({ playerStatus: 'loaded' }, document.referrer)
        if(!this.dateTimeTimer) this.dateTimeTimer = setInterval(() => this.updateDateTime(), 1000)
        // this.sendPlayerStatus('Player loaded from ' + document.referrer)
      } else {
        // this.sendPlayerStatus('Player are not load inside the iframe')
        if(window.player) window.player.dispose()
        window.location.href = 'https://elearn.tabf.org.tw/'
      }

      // if(document.referrer) {
      //   window.parent.postMessage({ playerStatus: 'loaded' }, document.referrer)
      // } else {
      //   window.player.dispose()
      // }
    })

    // this.player.addChild('vjsLogoOverlay', { watermarkLogoImage: this.state.watermarkLogoImage }, 5)
  }

  componentWillUnmount() {
    if(this.player) {
      this.player.dispose()
    }
  }

  watermarkDateTimeOverlay = () => {
    return (
      <div className="watermarkDateTime">
        <div>{ this.state.watermarkDate }</div>
        <div>{ this.state.watermarkTime }</div>
      </div>
    )
  }
  watermarkStudentOverlay = () => {
    return (
      <div className="watermarkStudent">
        <div>{ this.state.watermarkStudentText }</div>
      </div>
    )
  }
  watermarkLogoOverlay = () => {
    return (
      <div className="watermarkLogo">
        <img src={ this.state.watermarkLogoImage } alt="" />
      </div>
    )
  }

  warningOverlay = () => {
    return (
      <div className="warning">
        <img src={ iconWarning } alt="" />
        <div>{ this.state.warningText }</div>
      </div>
    )
  }

  render() {
    const className = "video-js vjs-default-skin vjs-big-play-centered phi-player"

    return (
      <div data-vjs-player>
        <video
          id="videoplayer"
          ref={ node => this.videoNode = node }
          className={ className }
          crossOrigin="anonymous"
        />
        { this.state.watermarkDateTime === 'show'? <this.watermarkDateTimeOverlay /> : null }
        { this.state.watermarkStudent === 'show'? <this.watermarkStudentOverlay /> : null }
        {/* { this.state.watermarkLogo === 'show'? <this.watermarkLogoOverlay /> : null } */}
        { this.state.warning === 'show'? <this.warningOverlay /> : null }
      </div>
    )
  }
}