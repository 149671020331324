import React from 'react'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

import { Player } from '../components'

export default function Routes() {
  return (
    <Router>
      <Route exact path="/" component={ Player } />
    </Router>
  )
}